// app/javascript/controllers/copy_text_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["text"];

  copyToClipboard(event) {
    event.preventDefault();
    const textToCopy = this.textTarget.dataset.toCopy;
    navigator.clipboard.writeText(textToCopy);
    // .then(() => {
    //   alert("Text copied to clipboard!");
    // })
    // .catch((err) => {
    //   console.error("Could not copy text: ", err);
    // });
  }
}
