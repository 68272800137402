import { Controller } from "stimulus"
import debounce from "lodash/debounce"

export default class extends Controller {
  static targets = [ "form", "search" ]

  connect() {
    //Removed until form submission running over turbo stream
    //Otherwise it creates jerkiness when entering search text
    //this.submit = debounce(this.submit.bind(this), 200)
  }

  submit() {
    this.formTarget.requestSubmit()
  }

  move_cursor_to_end(){
    this.searchTarget.setSelectionRange(-1, -1);
  }
}