import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["closed", "opened"]

  connect() {
    this.open = this.openedTarget.classList.contains("hidden")
  }

  toggle() {
    if (this.open) {
      this._hide()
    } else {
      this.show()
    }
  }

  show() {
    this.open = true
    this.openedTarget.classList.add("hidden")
    this.closedTarget.classList.remove("hidden")
  }

  _hide() {
    this.open = false
    this.openedTarget.classList.remove("hidden")
    this.closedTarget.classList.add("hidden")
  }

  hide() {
    if (this.element.contains(event.target) === false && this.open) {
      this._hide()
    }
  }
}