import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"];

  connect() {
    console.log("modal controller connected");
  }

  open(event) {
    event.preventDefault();

    this.modalTarget.showModal();
  }

  close(event) {
    event.preventDefault();

    this.modalTarget.close();
  }
}
