import { Controller } from "@hotwired/stimulus"
import CableReady from 'cable_ready'

// Connects to data-controller="supplied-by-association"
export default class extends Controller {
  static targets = [ 
    "search",
    "systemToAdd", 
    "systems"
  ]

  static values = {
    linkPath: {
      type: String, 
      default: ""
    },
    controllerPath: {
      type: String, 
      default: ""
    }
  }

  connect() {

  }

  filter_system_list(){
    this.show_supplied_by_nodes() //Verify results are visible
    const searchString = this.searchTarget.value
    //Select only the systemLink child elements (ignore close button, add system & edit systems links)
    const allSystems = Array.from(this.systemsTarget.querySelectorAll(".systemLink")) 
    
    allSystems.find(el => {
      if(el.textContent.toLowerCase().includes(searchString.toLowerCase()) ){
        this.show_element(el)
      } else {
        this.hide_element(el)
      }            
    });
  }

  show_supplied_by_nodes(){
    
    this.show_element(this.systemsTarget)
    this.show_element(document.querySelector(".hideSuppliedBySystems"))
  }
  hide_supplied_by_nodes(){
    this.hide_element(document.querySelector(".hideSuppliedBySystems"))
    this.hide_element(this.systemsTarget)
  }
  hide_element(el){
    if (!el.classList.contains("hidden")) {
      el.classList.add("hidden");
    }
  }
  show_element(el){
    if (el.classList.contains("hidden")) {
      el.classList.remove("hidden");
    }
  }

  associate_supplied_by_system(event){
         
    const baseSystem = event.params.baseSystem
    const associateWithSystem = event.params.associateWithSystem
    //Fetch server request to make association and handle cable response. 
    let fetch_path =  `/${this.controllerPathValue}/${baseSystem}/${this.linkPathValue}/${associateWithSystem}`
    
    fetch(fetch_path)
      .then(r => r.json())
      .then(CableReady.perform) 
  }

}
