import { Controller } from "stimulus"
import CableReady from 'cable_ready'

export default class extends Controller {
  static targets = [ 
    "form",
    "status",
    "updateVotesCount"
  ]
  static values = {    
    url: String,    
  }

  connect () {
    super.connect()  
    
  }

  hide_on_success () {
    //Add listener for response with once option to remove it on recorded event
    document.addEventListener("response_feedback:recorded", () => {
      //console.log("response_feedback:recorded")
      this.statusTarget.classList.remove("hidden")
      this.formTarget.classList.add("hidden")
    }, { once: true })
  }

  send_update_vote(event) {
    let confirmed = confirm("Does this procedure need to be updated?") 
    if (confirmed)   
    {
      // Make a get request to add a needs_update vote to this response.
      // Unhide feedback form on success
      fetch(event.params.url)
      .then(r => r.json())
      .then(CableReady.perform)
      .then(() => {this.formTarget.classList.remove("hidden");
        this.statusTarget.classList.add("hidden")
        this.hide_on_success();
      })

    }
    
  }  
}