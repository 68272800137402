import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="show-submit"
export default class extends Controller {
  static targets = ["button", "notes"]

  static values = {
    initialNotesContent:{
      type: String,
      default:""
    }
  }
  connect() {
    this.initialNotesContentValue = this.notesTarget.value
  }
  show() {   
    this.buttonTarget.classList.remove("hidden")
  }
  hide() {   
    this.buttonTarget.classList.add("hidden")
  }
  cancel(){
    this.notesTarget.value = this.initialNotesContentValue
    this.hide()
  }

}
