import { Controller } from "stimulus"
import CableReady from 'cable_ready'

export default class extends Controller {
  static targets = [ 
    "completedCount"
  ]
  static values = {    
    url: String,    
  }

  connect () {
    super.connect()      
  }

  send_completed(event) {    
    let confirmed = confirm("Mark this procedure as completed?") 
    if (confirmed)   
    {
      // Make a get request to add a completion count to this response.
      fetch(event.params.url)
      .then(r => r.json())
      .then(CableReady.perform)        
    }  
  }  
}