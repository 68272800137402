import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "slideover" ]

  connect() {
    this.backgroundHtml = this.backgroundHTML()
    this.visible = false 
  }

  disconnect() {
    if (this.visible) {
      this.close()
    }
  }

  open() {
    this.visible = true
    document.body.insertAdjacentHTML('beforeend', this.backgroundHtml)
    this.background = document.querySelector(`#slideover-background`)
    this.openSlideover()

    //Set focus to first text input within div with id=slideover-content. Use a timeout to give it a chance to render.
    setTimeout(() => {
      const firstInput = document.querySelector(`#slideover-content input[type=text]`)
      if (firstInput) {
        firstInput.focus()
      } 
    }, 500);

     //Reload if asked
    document.addEventListener("page:reload", () => {
      location.reload();
    }, { once: true })
    document.addEventListener("submit:success", () => {
      this.close()
    }, { once: true })
  }

  close() {
    this.visible = false
    this.closeSlideover()
    document.removeEventListener("page:reload", () => {
      location.reload();
    }, { once: true })
    if (this.background) {
      this.background.classList.remove("opacity-100")
      this.background.classList.add("opacity-0")
      setTimeout(() => {
        this.background.remove()
      }, 500);
    }
  }

  //Toggle caused issues when extra submit:success caught on page 
  toggleSlideover() {
    this.slideoverTarget.classList.toggle("right-0")
    this.slideoverTarget.classList.toggle("-right-full")
    this.slideoverTarget.classList.toggle("lg:-right-2/3")
  }
  openSlideover() {
    if(!this.slideoverTarget.classList.contains("right-0")){
      this.slideoverTarget.classList.add("right-0")
    }
    if(this.slideoverTarget.classList.contains("-right-full")){
      this.slideoverTarget.classList.remove("-right-full")
    }
    if(this.slideoverTarget.classList.contains("lg:-right-2/3")){
      this.slideoverTarget.classList.remove("lg:-right-2/3")
    }    
  }
  closeSlideover() {
    if(this.slideoverTarget.classList.contains("right-0")){
      this.slideoverTarget.classList.remove("right-0")
    }
    if(!this.slideoverTarget.classList.contains("-right-full")){
      this.slideoverTarget.classList.add("-right-full")
    }
    if(this.slideoverTarget.classList.contains("lg:-right-2/3")){
      this.slideoverTarget.classList.add("lg:-right-2/3")
    }    
  }
  backgroundHTML() {
    return `<div id="slideover-background" class="fixed top-0 left-0 w-full h-full z-20"></div>`;
  }
}