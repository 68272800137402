import { Controller } from "@hotwired/stimulus"
import CableReady from 'cable_ready'

// Connects to data-controller="select-search"
export default class extends Controller {
  static targets = [ 
    "search",
    "hiddenValue",
    "results", 
    "arrowUp",
    "arrowDown"
  ]
  static values = {
    itemClassname: {
      type: String, 
      default: ".selectableItem"
    }, 
    originalId:{
      type: String,
      default: ""
    },
    originalValue:{
      type: String,
      default: ""
    }
  }

  connect() {

  }
  
  add_missing_option_from_search(event){
    //Grab value from searchTarget, submit to controller action to create new record
    //Then add the response to the resultsTarget and hide the addSearchItem
    const searchString = this.searchTarget.value

    //Get the type from the hiddenValue
    const valueType = this.hiddenValueTarget.name
    //Post it to the server and get the cable response.
    // Response 

    console.log("Adding missing option for " + valueType + " from search: " + searchString)
    let fetch_path =  `/add_new_object/?type=${valueType}&value=${searchString}`
    
    fetch(fetch_path)
      .then(r => r.json())
      .then(CableReady.perform) 
  }

  filter_search_box(){
    this.show_search_box() //Verify results are visible
    const searchString = this.searchTarget.value    
    //Select only the systemLink child elements (ignore close button, add system & edit systems links)
    const allResults = Array.from(this.resultsTarget.querySelectorAll(this.itemClassnameValue)) 
    
    let hasExactMatch = false

    allResults.find(el => {
      if(el.textContent.toLowerCase().includes(searchString.toLowerCase()) ){
        //Check if it's an exact match (case insensitive)
        if (   el.textContent.toLowerCase().trim() == searchString.toLowerCase().trim()) {
          hasExactMatch = true
        }
        this.show_element(el)
      } else {
        this.hide_element(el)
      }            
    });

    //If there is no exact match, make the child element with addSearchItem class visible
    const addSearchItem = this.resultsTarget.querySelector(".addSearchItem")

    //Update the addSearchItem text to match the search string
    if (!hasExactMatch && searchString.length > 0) {     
      this.show_element(addSearchItem)
      addSearchItem.textContent = "Add " + searchString
    } else {
      addSearchItem.textContent = "Add ..." 
      this.hide_element(addSearchItem)
    }
  }

  handleEsc = (event) => {
    if (event.key === 'Escape') {
      this.hide_search_box();
      this.resetValues();
    }
  }

  show_search_box(){
    const allResults = Array.from(this.resultsTarget.querySelectorAll(this.itemClassnameValue))
    this.show_elements(allResults)
    this.show_element(this.resultsTarget)
    this.hide_element(this.arrowDownTarget)
    this.show_element(this.arrowUpTarget)
    document.addEventListener('keydown', this.handleEsc, {once: true})    
  }
  hide_search_box(){
    this.hide_element(this.resultsTarget)
    this.hide_element(this.arrowUpTarget)
    this.show_element(this.arrowDownTarget)
    document.removeEventListener('keydown', this.handleEsc, {once: true})
  }
  choose_option(event){
    //When choosing an option - update the values in the searchTarget and hiddenValueTarget
    //First check the existing value vs the clicked value and whether there is anything currently in the selected value.
    // If there is a previous entry selected and it matches this one, clear it.
    if (this.originalIdValue == event.params.targetId && this.searchTarget.value != "")
    {
      this.hiddenValueTarget.value = ""
      this.searchTarget.value = ""
    } else {
      //If they do not, set them
      this.hiddenValueTarget.value = event.params.targetId
      this.searchTarget.value = event.params.targetValue
    }
    this.hide_search_box();
  }
  resetValues(){
    this.searchTarget.value = this.originalValueValue
    this.hiddenValueTarget.value = this.originalIdValue
  }

  hide_element(el){
    if (!el.classList.contains("hidden")) {
      el.classList.add("hidden");
    }
  }
  show_element(el){
    if (el.classList.contains("hidden")) {
      el.classList.remove("hidden");
    }
  }
  show_elements(els){
    els.forEach(el => this.show_element(el));
  }
}
