import { Controller } from "stimulus"
import CableReady from 'cable_ready'

export default class extends Controller {
  static targets = [ 
    "form", 
    "template",
    "reset"
  ]

  connect () {
    super.connect()    
  }

  load_into_form(event) {    
    //this function should fetch the specified template and set the form fields as specified in the template.
    //Get the alarm template id, load from server
    //TODO load url from a parameter instead of coding it here. Use token replacement for id.
    if (this.templateTarget.value){
      let template_fetch_path = "/alarm_templates/"+this.templateTarget.value+"/load_into_form";
      // Get the alarm form data
      const formData = new FormData(this.formTarget);
      // fetch the template using post
      fetch(template_fetch_path, {
        method: 'PATCH',
        body: formData,
        headers: {
          'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
        }
      })
      .then(r => r.json())
      .then(CableReady.perform)
    } else {
      //Skip fetch for empty template.
      //Reset the select fields loaded from template
      this.resetTargets.forEach((el)=>{el.value = null;});
    }
    

  }  
}