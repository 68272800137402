import { Controller } from "@hotwired/stimulus"
import CableReady from 'cable_ready'


// Connects to data-controller="multiselect"
export default class extends Controller {
  //Set a value to store an array of selected values
  static values = {  selected: Array }
  static targets = [ "checkboxes", "actionMenu", "actionMenuSpacer" ]

  connect() {
    super.connect()  
    console.log("connected multiselect")
  }

  initialize() {
    this.selectedValue = this.selectedValue || []
  }

  // On click, add the value to the selected array if it isn't there.
  // If it is there, remove it.
  toggle(event) {
    let value = event.target.id

    if (this.selectedValue.includes(value)) {
      this.selectedValue = this.selectedValue.filter((item) => item !== value)
      // Hide the selected icon and show the unselected icon      
      event.target.children[0].classList.remove("hidden")
      event.target.children[1].classList.add("hidden")
    } else {
      this.selectedValue = [...this.selectedValue, value]
      // Hide the unselected icon and show the selected icon
      event.target.children[0].classList.add("hidden")
      event.target.children[1].classList.remove("hidden")
    }
    this.updateActionBar();
  }

  // Check whether there are any selected values, if show, make sure the action menu is displayed
  // If not, hide the action menu
  updateActionBar() {
    if (this.selectedValue.length > 0) {
      this.actionMenuTarget.classList.remove("hidden")    
      this.actionMenuSpacerTarget.classList.add("hidden")  
    } else {
      this.actionMenuTarget.classList.add("hidden")
      this.actionMenuSpacerTarget.classList.remove("hidden")
    }
  }

  // Handle action bar buttons - duplicate for starters
  duplicate(event) {    
    //event.preventDefault(); // Prevent the link from reloading the page? Prevents the cable car operations from working

    //Submit a request to the server to duplicate the selected items, then handle the cable car operations in the response
    fetch("/alarms/duplicate_list/?alarm_ids="+this.selectedValue)    
    .then(r => r.json())
    .then(CableReady.perform) 
      
    //return false
  }

  delete(event) {
    fetch("/alarms/delete_list/?alarm_ids="+this.selectedValue)    
    .then(r => r.json())
    .then(CableReady.perform) 
         
  }


}
